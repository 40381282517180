import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import { useAuthContext } from "./Auth";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "auth/FirebaseAuth";
import { isEmpty, head, get } from "lodash";
import { updateOrganisation as updateOrganisationDb } from "services/FirebaseService";
import { message } from "antd";
import {
  getCurrentOrganizationOfLocalStorage,
  setCurrentOrganizationOfLocalStorage,
} from "utils/localStorage";
import { useSpinnerContext } from "./Spinner";

export const CurrentOrganisationContext = createContext();

export const CurrentOrganisationProvider = ({ children }) => {
  const [currentOrganisation, setCurrentOrganization] = useState({});
  const [isOwner, setIsOwner] = useState(false);
  const { user } = useAuthContext();
  const {setIsLoadingSpinner} = useSpinnerContext();

  const getCurrentOrganisationId = (userData) => {
    const currentOrganizationIdInLocalStorage =
      getCurrentOrganizationOfLocalStorage(userData.id);

    if (isEmpty(currentOrganizationIdInLocalStorage)) {
      const currentOrganisationId = head(Object.keys(userData.organisations));
      setCurrentOrganizationOfLocalStorage(userData.id, currentOrganisationId);

      return currentOrganisationId;
    }

    return currentOrganizationIdInLocalStorage;
  };

  const fetchCurrentOrganizationData = useCallback(async () => {
    try {
      if (!get(user, "organisations", false) || isEmpty(user.organisations))
        return;

      setIsLoadingSpinner(true);

      const getOrganisationData = httpsCallable(
        firebaseFunctions,
        "getOrganisationData"
      );
      const currentOrganisationId = getCurrentOrganisationId(user);
      const organisationData = await getOrganisationData({
        id: currentOrganisationId,
      });
      setIsOwner(!isEmpty(organisationData.data.owner[user.id]));
      setCurrentOrganization(organisationData.data);
      setIsLoadingSpinner(false);
    } catch (error) {
      console.error("Failed to get currentOrganisation data");
      message.error({
        content: "Failed to load Organisation!",
        key: "fetchCurrentOrganizationData",
        duration: 3,
      });
      throw new Error("Failed to get currentOrganisation data");
    }
  }, [user]);

  const updateOrganisation = useCallback(
    async (organisationDataToUpdate) => {
      try {
        await updateOrganisationDb(
          currentOrganisation.id,
          organisationDataToUpdate
        );
        await fetchCurrentOrganizationData();
      } catch (error) {
        console.error(error);
        throw new Error("Failed to update organisation data");
      }
    },
    [fetchCurrentOrganizationData, currentOrganisation.id]
  );

  useEffect(() => {
    if (!user?.organisations) return;

    fetchCurrentOrganizationData();
  }, [user, fetchCurrentOrganizationData]);

  const values = useMemo(
    () => ({
      currentOrganisation,
      fetchCurrentOrganizationData,
      isOwner,
      updateOrganisation,
    }),
    [
      currentOrganisation,
      fetchCurrentOrganizationData,
      isOwner,
      updateOrganisation,
    ]
  );

  return (
    <CurrentOrganisationContext.Provider value={values}>
      {children}
    </CurrentOrganisationContext.Provider>
  );
};

export const useCurrentOrganisationContext = () => {
  const context = useContext(CurrentOrganisationContext);
  if (!context) {
    throw new Error(
      "useCurrentOrganisationContext must be used within a CurrentOrganisationProvider"
    );
  }
  return context;
};
